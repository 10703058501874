<template>
  <v-app>
    <v-container>
      <v-alert v-model="success" type="success" dismissible>
        {{dialogText}}
      </v-alert>
      <v-alert v-model="error" type="error" dismissible>
        {{dialogText}}
      </v-alert>
      <v-card class="mt-3">
        <v-row>
          <v-btn @click="getLastData()" :dark="!isLatestDataLoad" color="blue" class="ml-5 mt-1 mb-n2" style="font-size:16px; height: 30px; width: 60px;" :loading="isLatestDataLoad" :disabled="isLatestDataLoad">更新</v-btn>
          <v-checkbox class="mt-1 mb-n2 pl-3" v-model="checkbox" label="自動更新"  v-on:change="autoSaveCheck" style="font-size:16px"></v-checkbox>
          <div style="visibility:hidden">{{ version }}</div>
        </v-row>
      
        <v-data-table
          :headers="headers"
          :items="listitems"
          :items-per-page="100"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          item-key="dev_id"
          class="elevation-1 my-3"
          @click:row="clickRow"
        >
          <!-- エラーチェック -->
          <template v-slot:[`item.co2`]="{ item }">
            <v-chip v-if="item.last_update_time" label color="rgba(255, 255, 255, 0.0)" :class="getColorVal(item, item.co2_warningThresholdHigh, item.co2_warningThresholdLow, item.co2_cautionThresholdHigh, item.co2_cautionThresholdLow, 1)">{{ item.co2 }}</v-chip>
          </template>
          <template v-slot:[`item.temperature`]="{ item }">
            <v-chip v-if="item.last_update_time" label color="rgba(255, 255, 255, 0.0)" :class="getColorVal(item, item.temperature_warningThresholdHigh, item.temperature_warningThresholdLow, item.temperature_cautionThresholdHigh, item.temperature_cautionThresholdLow, 3)">{{ item.temperature }}</v-chip>
          </template>
          <template v-slot:[`item.humidity`]="{ item }">
            <v-chip v-if="item.last_update_time" label color="rgba(255, 255, 255, 0.0)" :class="getColorVal(item, item.temperature_warningThresholdHigh, item.temperature_warningThresholdLow, item.temperature_cautionThresholdHigh, item.temperature_cautionThresholdLow, 4)">{{ item.humidity }}</v-chip>
          </template>
          <template v-slot:[`item.airflow`]="{ item }">
            <v-chip v-if="item.last_update_time" label color="rgba(255, 255, 255, 0.0)" :class="getColorVal(item, item.airflow_warningThresholdHigh, item.airflow_warningThresholdLow, item.airflow_cautionThresholdHigh, item.airflow_cautionThresholdLow, 2)">{{ item.airflow }}</v-chip>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import SensorData from "../js/SensorData";
import {getUser} from '@/js/auth.js'
import Conf from '@/config/conf.js'

export default {
  data () {
    return {
      lastdata: true,
      checkbox: false,
      loading: false,
      timer:'',
      sortBy:"last_update_time",
      sortDesc:true,
      hosturl: process.env.VUE_APP_APIURL,
      headers: [
        { text: 'ロケーション名', value: 'dev_name' },
        { text: 'CO2(ppm)', value: 'co2' },
        { text: '温度(℃)', value: 'temperature' },
        { text: '湿度(％)', value: 'humidity' },
        { text: '風速(cm/s)', value: 'airflow' },
        { text: '最終データ日時', value: 'last_update_time' },
        {
          text: 'シリアル番号',
          sortable: true,
          value: 'dev_id',
        },
      ],
      listitems: [],
      deviceEnableList:{},
      loginuser:null,
      version:Conf.version,
      dialogText:null,
      success:false,
      error:false,
      isLatestDataLoad:false,
    }
  },
  methods: {
    //センサID取得
    current: function(id){
      //本格的に対応する場合、ログインID＋devidをキーとする
      this.$store.commit('setDevice', id);
      this.$router.replace("/detail");
    },
    
    //セル色変更
    getColorVal:function(item, danmax, danmin, warmax, warmin, chkflg) {
      var val = ""
      switch(chkflg){
        case 1:
          val = item.co2;
          break;
        case 2:
          val = item.airflow;
          break;
        //case 3:
        //  val = item.temperature;
        //  break;
        //  
        //case 4:
        //  val = item.humidity;
        //  break;
          
        //WBGT使用時はこっち
        case 3:
        case 4:
          val = SensorData.getWBGTData(item.temperature, item.humidity);
          break;
      }

      if (val >= danmax || val <= danmin) return 'red--text font-weight-bold'
      else if (val >= warmax || val <= warmin) return 'orange--text font-weight-bold'
      else return 'black--text'
    },
    
    //センサ情報取得処理
    getLastData:function(){
      this.isLatestDataLoad = true;
      this.error = false
      var grouplist = []
      getUser().then((user) => {
        if(user){
          this.$axios.defaults.headers.common['Authorization'] = user.signInUserSession.idToken.jwtToken;
          if(this.$store.state.sensorgroup.length === 0){
            //ログイン時
            console.log('group: None')
            var apiurl = this.hosturl + "/user_sensor_group/" + user.username
            this.$axios.get(apiurl).then(res => {
              var userdetail = res.data[user.username]
              this.loginuser = userdetail['loginid']
              this.$store.commit('setSensorGroup', userdetail.sensorgroupid);
              
              grouplist = userdetail.sensorgroupid
              this.getLastDataMethod(grouplist)
            }, this)
            .catch((err) => {
              console.log(err);
              this.dialogText = 'センサグループの取得に失敗しました。';
              this.success = false;
              this.error = true;
              this.returnTop('auto');
              this.isLatestDataLoad = false;
            });
          } else {
            //ログイン時以外
            console.log('group: Exist')
            if(location.pathname === '/lastdata'){
              grouplist = this.$store.state.sensorgroup
              this.loginuser = this.$store.state.user.username
              this.getLastDataMethod(grouplist)
            } else {
              console.log('別画面：タイマー停止')
              this.timerStop();
            }
          }
        } else {
          console.log('ログアウト：タイマー停止')
          this.timerStop();
        }
      })
    },
    getLastDataMethod:function( grouplist ){
      if(grouplist.length===0){
        setTimeout(()=> {this.isLatestDataLoad = false;},1000);
      }
      var sensorList = []
      var count = 1;
      grouplist.forEach(function( group ){
        //DynamoDB
        var apiurl = this.hosturl + "/sensors/?sensorgroupid=" + group + "&loginid=" + this.loginuser;
        
        this.$axios.get(apiurl).then(res => {
          for(var i = 0;i < res['data'].length;i++){
            var item = res.data[i];
            if(item['dev_id'].indexOf('evs_') != -1){
              item['dev_id'] = item['dev_id'].replace('evs_','');
            }
            sensorList.push(item);
          }
          if(count === grouplist.length){
            setTimeout(()=> {this.isLatestDataLoad = false;},1000);
          }
          count++;
        })
        .catch((err) => {
          console.log(err);
          this.dialogText = group + 'のセンサの取得に失敗しました。';
          this.success = false;
          this.error = true;
          this.returnTop('auto');
          this.isLatestDataLoad = false;
        });
        
      }, this)
      //取得時は更新時間降順にソート
      sensorList.sort(function(a, b){
        if(a.last_update_time > b.last_update_time){
          return -1;
        } else {
          return 1;
        }
      });
      this.listitems = sensorList
    },
    //自動更新切替
    autoSaveCheck:function(){
      if(this.checkbox){
        this.timerStart();
      } else {
        this.timerStop();
      }
    },
    
    //timerスタート
    timerStart:function(){
      var fn = () =>this.getLastData();
      this.timer = setInterval(fn,60000);
    },
    
    //timerストップ
    timerStop:function(){
      clearInterval(this.timer);
    },
    clickRow: function(item) {
      //alert(item.dev_id)
      this.current(item.dev_id)
    },

    returnTop(action) {
      window.scrollTo({
        top: 0,
        behavior: action
      })
    },
  },
  //画面表示時（部品配置後）
  mounted() {
    this.autoSaveCheck()
    this.getLastData()
  },
  beforeDestroy(){
    this.timerStop();  
  }
}
</script>
