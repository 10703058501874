var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',[_c('v-alert',{attrs:{"type":"success","dismissible":""},model:{value:(_vm.success),callback:function ($$v) {_vm.success=$$v},expression:"success"}},[_vm._v(" "+_vm._s(_vm.dialogText)+" ")]),_c('v-alert',{attrs:{"type":"error","dismissible":""},model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}},[_vm._v(" "+_vm._s(_vm.dialogText)+" ")]),_c('v-card',{staticClass:"mt-3"},[_c('v-row',[_c('v-btn',{staticClass:"ml-5 mt-1 mb-n2",staticStyle:{"font-size":"16px","height":"30px","width":"60px"},attrs:{"dark":!_vm.isLatestDataLoad,"color":"blue","loading":_vm.isLatestDataLoad,"disabled":_vm.isLatestDataLoad},on:{"click":function($event){return _vm.getLastData()}}},[_vm._v("更新")]),_c('v-checkbox',{staticClass:"mt-1 mb-n2 pl-3",staticStyle:{"font-size":"16px"},attrs:{"label":"自動更新"},on:{"change":_vm.autoSaveCheck},model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}}),_c('div',{staticStyle:{"visibility":"hidden"}},[_vm._v(_vm._s(_vm.version))])],1),_c('v-data-table',{staticClass:"elevation-1 my-3",attrs:{"headers":_vm.headers,"items":_vm.listitems,"items-per-page":100,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"item-key":"dev_id"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"click:row":_vm.clickRow},scopedSlots:_vm._u([{key:"item.co2",fn:function(ref){
var item = ref.item;
return [(item.last_update_time)?_c('v-chip',{class:_vm.getColorVal(item, item.co2_warningThresholdHigh, item.co2_warningThresholdLow, item.co2_cautionThresholdHigh, item.co2_cautionThresholdLow, 1),attrs:{"label":"","color":"rgba(255, 255, 255, 0.0)"}},[_vm._v(_vm._s(item.co2))]):_vm._e()]}},{key:"item.temperature",fn:function(ref){
var item = ref.item;
return [(item.last_update_time)?_c('v-chip',{class:_vm.getColorVal(item, item.temperature_warningThresholdHigh, item.temperature_warningThresholdLow, item.temperature_cautionThresholdHigh, item.temperature_cautionThresholdLow, 3),attrs:{"label":"","color":"rgba(255, 255, 255, 0.0)"}},[_vm._v(_vm._s(item.temperature))]):_vm._e()]}},{key:"item.humidity",fn:function(ref){
var item = ref.item;
return [(item.last_update_time)?_c('v-chip',{class:_vm.getColorVal(item, item.temperature_warningThresholdHigh, item.temperature_warningThresholdLow, item.temperature_cautionThresholdHigh, item.temperature_cautionThresholdLow, 4),attrs:{"label":"","color":"rgba(255, 255, 255, 0.0)"}},[_vm._v(_vm._s(item.humidity))]):_vm._e()]}},{key:"item.airflow",fn:function(ref){
var item = ref.item;
return [(item.last_update_time)?_c('v-chip',{class:_vm.getColorVal(item, item.airflow_warningThresholdHigh, item.airflow_warningThresholdLow, item.airflow_cautionThresholdHigh, item.airflow_cautionThresholdLow, 2),attrs:{"label":"","color":"rgba(255, 255, 255, 0.0)"}},[_vm._v(_vm._s(item.airflow))]):_vm._e()]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }